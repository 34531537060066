import React, { ReactElement } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import Brand from "../../assets/brand.svg";

import styles from "./Stub.module.css";

interface Props {
  type: string;
  children: ReactElement;
  title: string | ReactElement;
  reason?: string | ReactElement;
  backgroundImage?: string;
}

export default function StubWithAction({ type, children, title, reason, backgroundImage }: Props): ReactElement {
  return (
    <div className={styles.stubContainer} id={type}>
      {backgroundImage && <Image src={backgroundImage} className={styles.stubBackgroundImage} alt="" />}
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div>
              <div className="mt-5 mt-md-0 mx-auto mx-md-0 text-center text-md-left">
                <Image src={Brand} alt="brand" style={{ width: 300 }} />
              </div>
              <h2 className="text-center text-md-left">{title}</h2>
              {reason && <h4 className="text-center text-md-left text-muted">{reason}</h4>}
            </div>
          </Col>
          <Col className="mt-4 mt-md-0" md="6">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
